import React, {useEffect, useRef, useState} from 'react';

import {on, off} from '../../utils/events';
import constantsFactory from '../../utils/constants';

const {EVENTS} = constantsFactory();

const NotificationSlider = () => {
  const notificationArea: any = useRef();
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(null);
  const delayedClose = 3000;

  useEffect(() => {
    on(EVENTS.OPEN_NOTIFICATION_SLIDER, showNotification);
    on(EVENTS.CLOSE_NOTIFICATION_SLIDER, closeNotificationTimer);
    return () => {
      off(EVENTS.OPEN_NOTIFICATION_SLIDER, showNotification);
      off(EVENTS.CLOSE_NOTIFICATION_SLIDER, closeNotificationTimer);
    };
  }, []);

  const showNotification = () => {
    setShow(true);
  };

  const closeNotification = () => {
    setShow(false);
  };

  const closeNotificationTimer = () => {
    setTimeout(closeNotification, delayedClose);
  };

  useEffect(() => {
    on(EVENTS.OPEN_NOTIFICATION_SLIDER, setContent);
    on(EVENTS.CLOSE_NOTIFICATION_SLIDER, setContent);
    return () => {
      off(EVENTS.OPEN_NOTIFICATION_SLIDER, setContent);
      off(EVENTS.CLOSE_NOTIFICATION_SLIDER, setContent);
    };
  });

  useEffect(() => {});

  return (
    <div
      className={`notification-slider${show ? ' show' : ''}`}
      ref={notificationArea}
    >
      <button
        className="close"
        type="button"
        aria-label="Close"
        onClick={closeNotification}
      >
        <span aria-hidden="true">×</span>
      </button>
      {content ? content : ''}
    </div>
  );
};

export default NotificationSlider;
