import React, {useState, useEffect, useRef} from 'react';

import _ from '../../utils/_';
import authServiceFactory, {authMock} from '../../services/auth-service';
import {on, off, emit} from '../../utils/events';
import elementFactory from '../../utils/element';
import constantsFactory from '../../utils/constants';
import modalFactory, {transitionZero} from '../../../vendors/bootstrap/components/modal';

import Loading from '../loading/loading';
import Button from '../button/button';
import {H5} from '../typography/typography';

const defaultState = () => ({email: '', password: '', submitted: false});

const ModalLogin = () => {
  const target = useRef(null);
  const [auth, setAuth] = useState(authMock());
  const [loading, setLoading] = useState(false);
  const [error, setError]: any = useState(null);
  const [form, setForm] = useState(defaultState());

  useEffect(() => {
    setAuth(authServiceFactory(document, window));
  }, []);

  useEffect(() => {
    const $ = elementFactory(document, window);
    const {EVENTS, KEYS} = constantsFactory();
    const openTriggers = [];
    const closeTriggers = $.findAll('[data-dismiss="modal"]', target.current);
    const modal = modalFactory($, _, document, window, KEYS);
    const instance = modal(
      openTriggers,
      closeTriggers,
      target.current,
      _.merge(transitionZero(), {}),
    );

    const open = () => {
      setTimeout(instance.open, 0);
    };

    on(EVENTS.CLICK_ON_LOGIN, open);
    on(EVENTS.LOGIN_SUCCESS, instance.close);
    on(EVENTS.CLICK_ON_JOIN, instance.close);

    return () => {
      off(EVENTS.CLICK_ON_LOGIN, open);
      off(EVENTS.LOGIN_SUCCESS, instance.close);
      off(EVENTS.CLICK_ON_JOIN, instance.close);
      instance.destroy();
    };
  }, [target]);

  const handleChange = (name) => (e) => {
    setForm(_.assoc(name, e.target.value, form));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    auth
      .login(form)
      .then(() => {
        setForm(defaultState());
      })
      .catch(_.pipe(_.prop('errors'), setError))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div ref={target} className="modal modal-login" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {loading && <Loading />}
          <div className="modal-header">
            <H5 style="art">Log in to Your Account</H5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error.map((x, i) => (
                  <div key={i}>{x.title}</div>
                ))}
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="modal-login-email">Email address</label>
                <input
                  type="email"
                  name="email"
                  value={form.email}
                  className={'form-control' + (false ? ' is-invalid' : '')}
                  id="modal-login-email"
                  placeholder="Enter email"
                  onChange={handleChange('email')}
                />
                {false && <div className="invalid-feedback">Please enter your username.</div>}
              </div>
              <div className="form-group">
                <label htmlFor="modal-login-password">Password</label>
                <input
                  type="password"
                  name="password"
                  value={form.password}
                  className="form-control"
                  id="modal-login-password"
                  placeholder="Password"
                  onChange={handleChange('password')}
                  autoComplete="off"
                />
              </div>

              <Button submit wide>
                Log in
              </Button>
            </form>

            <div className="modal-login-link">
              <a href="/login/forgot/">Forgot password?</a>
            </div>
            <div className="modal-login-link">
              Not yet registered?{' '}
              <a
                href="/signup/"
                onClick={(e) => {
                  e.preventDefault();
                  emit('CLICK_ON_JOIN', {openDelay: 0});
                }}
              >
                Create an account
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLogin;
