import React from 'react';

const SVG = ({width = '23px', height = '23px'}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 198.4 198.4"
    className="bi bi-check"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Check</title>
    <g>
      <path
        fillRule="evenodd"
        d="M32.9,104.4c-1-1-1.6-2.6-1.6-3.7s0.5-2.6,1.6-3.7l7.3-7.3c2.1-2.1,5.2-2.1,7.3,0l0.5,0.5l28.7,30.8c1,1,2.6,1,3.7,0l70-72.6h0.5l0,0c2.1-2.1,5.2-2.1,7.3,0l7.3,7.3c2.1,2.1,2.1,5.2,0,7.3l0,0L82,149.9c-1,1-2.1,1.6-3.7,1.6s-2.6-0.5-3.7-1.6L33.9,106L32.9,104.4z"
      />
    </g>
  </svg>
);

export default SVG;
