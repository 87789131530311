import React, {useEffect, useRef} from 'react';

import _ from '../../utils/_';
import elementFactory from '../../utils/element';
import constantsFactory from '../../utils/constants';
import dropdownFactory from '../../../vendors/bootstrap/components/dropdown';
import Caret from '../icons-af/caret';

const DropdownSelect = ({
  id,
  label,
  items,
  value,
  getName = _.prop('name'),
  getValue = (_, i) => i,
  onChange,
  alignment,
  disabled = false,
}: propTypes) => {
  const trigger = useRef(null);
  const target = useRef(null);

  useEffect(() => {
    const $ = elementFactory(document, window);
    const constants = constantsFactory();
    const dropdown = dropdownFactory($, _, document, window, constants.KEYS);
    const instance = dropdown(trigger.current, target.current, {
      beloworigin: false,
      alignment: alignment,
    });

    return () => {
      instance.destroy();
    };
  }, [trigger, target]);

  const handleClick = (index) => () => {
    onChange(getValue(items[index], index));
  };

  return (
    <div className={'dropdown-select' + (disabled ? ' disabled' : '')}>
      {label && <label htmlFor={id}>{label}</label>}
      <button
        ref={trigger}
        type="button"
        aria-haspopup="listbox"
        className="form-control text-truncate"
        id={id}
        disabled={disabled}
      >
        {getName(
            items[getValue(_, value)] ||
            items.find((x, i) => getValue(x, i) === value) ||
            {},
        )}
        <Caret height="100%" width="18px" />
      </button>
      <ul
        ref={target}
        className="dropdown-menu"
        role="listbox"
        aria-labelledby={id}
      >
        {items.map((x, i) => (
          <li
            role="option"
            key={i}
            className={
              'dropdown-item' + (getValue(x, i) === value ? ' active' : '')
            }
            onClick={handleClick(i)}
          >
            {getName(x)}
          </li>
        ))}
      </ul>
    </div>
  );
};

interface propTypes {
  id: string;
  label?: string;
  value: any;
  items: {}[];
  getName?: (item) => string;
  getValue?: (item, index?) => any;
  onChange: (item) => any;
  alignment?: 'left' | 'right';
  disabled?: boolean;
}

export default DropdownSelect;
