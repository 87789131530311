import React, {useState, useEffect, useRef} from 'react';

import _ from '../../utils/_';
import {on, off, emit} from '../../utils/events';
import elementFactory from '../../utils/element';
import constantsFactory from '../../utils/constants';
import modalFactory from '../../../vendors/bootstrap/components/modal';
import {transitionZero} from '../../../vendors/bootstrap/components/modal';

import {post} from '../../services/artfinder-service';
import Loading from '../loading/loading';
import DropdownSelect from '../dropdown-select/dropdown-select';
import Button from '../button/button';
import {H5} from '../typography/typography';

const userTypes = [
  {name: '---------', value: ''},
  {name: 'An art lover', value: 'art_lover'},
  {name: 'An artist looking to sell', value: 'artist'},
  {
    name: 'A professional (buying for clients)',
    value: 'trade_client',
  },
];

const days = _.pipe(
    () => _.range(1, 32),
    _.map((x) => ({name: x, value: x})),
    _.prepend({name: 'Day', value: ''}),
);

const months = _.pipe(
    _.monthList,
    (xs) => xs.map((x, i) => ({name: x, value: i + 1})),
    _.prepend({name: 'Month', value: ''}),
);

const years = _.pipe(
    (y) => _.range(y - 120, y + 1),
    _.sortBy(_.descend(_.identity)),
    _.map((x) => ({name: x, value: x})),
    _.prepend({name: 'Year', value: ''}),
);

const defaultState = () => ({
  first_name: '',
  last_name: '',
  user_type: '',
  date_of_birth_day: '',
  date_of_birth_month: '',
  date_of_birth_year: '',
  submitted: false,
});

const ModalYourself = () => {
  const target = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError]: any = useState(null);
  const [form, setForm] = useState(defaultState());

  useEffect(() => {
    const $ = elementFactory(document, window);
    const {EVENTS, KEYS} = constantsFactory();
    const openTriggers = [];
    const closeTriggers = $.findAll('[data-dismiss="modal"]', target.current);
    const modal = modalFactory($, _, document, window, KEYS);
    const instance = modal(
        openTriggers,
        closeTriggers,
        target.current,
        _.merge(transitionZero(), {}),
    );

    const open = () => {
      setTimeout(instance.open, 0);
    };

    on(EVENTS.CLICK_TELL_ABOUT_YOURSELF, open);
    on(EVENTS.USER_UPDATED, instance.close);

    return () => {
      off(EVENTS.CLICK_TELL_ABOUT_YOURSELF, open);
      off(EVENTS.USER_UPDATED, instance.close);
      instance.destroy();
    };
  }, [target]);

  const handleChange = (name) => (e) => {
    setForm(_.assoc(name, e.target.value, form));
  };

  const handleSelectChange = (name) => (value) => {
    setForm(_.assoc(name, value, form));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    post('/u/settings/update/', form)
        .then((res) => {
          console.log('--res', res);
        })
        .catch(setError)
        .finally(() => {
          setLoading(false);
          emit('USER_UPDATED', form);
        });
  };

  return (
    <div
      ref={target}
      className="modal modal-yourself"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          {loading && <Loading />}
          <div className="modal-body">
            <div className="modal-yourself-half">
              <img
                loading="lazy"
                src="https://d2m7ibezl7l5lt.cloudfront.net/img/onboarding/data.ff443c180fb3.jpg"
                alt="Painting workbench"
                width="auto"
                height="625px"
              />
            </div>
            <div className="modal-yourself-half">
              <H5 style="title" align="center">
                Tell us about yourself
              </H5>
              {error && (
                <div className="alert alert-danger" role="alert">
                  {Object.keys(error).map((x) => (
                    <div key={x}>
                      {x} : {error[x]}
                    </div>
                  ))}
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="modal-yourself-firstname">My name is</label>
                  <input
                    type="text"
                    name="first_name"
                    value={form.first_name}
                    className={'form-control' + (false ? ' is-invalid' : '')}
                    id="modal-yourself-firstname"
                    placeholder="First Name"
                    onChange={handleChange('first_name')}
                  />
                  {false && (
                    <div className="invalid-feedback">
                      Please enter your first name.
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="modal-yourself-lastname">Last name</label>
                  <input
                    type="text"
                    name="last_name"
                    value={form.last_name}
                    className="form-control"
                    id="modal-yourself-lastname"
                    placeholder="Last Name"
                    onChange={handleChange('last_name')}
                  />
                </div>
                <div className="form-group">
                  <DropdownSelect
                    id="modal-yourself-usertype"
                    label="And I am..."
                    value={form.user_type}
                    getValue={_.prop('value')}
                    onChange={handleSelectChange('user_type')}
                    items={userTypes}
                  />
                </div>
                <div className="form-group form-group-dob">
                  <DropdownSelect
                    id="modal-yourself-day"
                    value={form.date_of_birth_day}
                    getValue={_.prop('value')}
                    onChange={handleSelectChange('date_of_birth_day')}
                    items={days()}
                  />
                  <DropdownSelect
                    id="modal-yourself-month"
                    value={form.date_of_birth_month}
                    getValue={_.prop('value')}
                    onChange={handleSelectChange('date_of_birth_month')}
                    items={months()}
                  />
                  <DropdownSelect
                    id="modal-yourself-year"
                    value={form.date_of_birth_year}
                    getValue={_.prop('value')}
                    onChange={handleSelectChange('date_of_birth_year')}
                    items={years(new Date().getFullYear())}
                  />
                </div>

                <Button submit wide>
                  Submit
                </Button>
              </form>

              <div className="modal-yourself-link">
                <a href="#" data-dismiss="modal">
                  Skip straight to the art
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalYourself;
