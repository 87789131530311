import React, {useState, useEffect} from 'react';

import {on, off} from '../../utils/events';
import constantsFactory from '../../utils/constants';

import Check from '../icons-af/check';

const AlertWelcome = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const {EVENTS} = constantsFactory();
    on(EVENTS.REGISTRATION_SUCCESSFUL, open);
    return () => {
      off(EVENTS.REGISTRATION_SUCCESSFUL, open);
    };
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="alert alert-success alert-welcome" role="alert">
      <div className="container">
        <Check /> Welcome to Artfinder! Please check your email to confirm your
        account
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={close}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  );
};

export default AlertWelcome;
