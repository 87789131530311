import React, {useState, useEffect, useRef} from 'react';

import _ from '../../utils/_';
import {on, off, emitFactory} from '../../utils/events';
import elementFactory from '../../utils/element';
import constantsFactory from '../../utils/constants';
import modalFactory from '../../../vendors/bootstrap/components/modal';
import {transitionZero} from '../../../vendors/bootstrap/components/modal';

import Loading from '../loading/loading';
import Logo from '../icons-af/logo';
import useAuth from '../../hooks/use-auth/use-auth';
import Button from '../button/button';
import {H5, P} from '../typography/typography';

const ModalJoin = () => {
  const target = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError]: any = useState(null);
  const [inputEmail, setInputEmail] = useState('');
  const auth = useAuth();

  useEffect(() => {
    const $ = elementFactory(document, window);
    const {EVENTS, KEYS} = constantsFactory();
    const openTriggers = [];
    const closeTriggers = $.findAll('[data-dismiss="modal"]', target.current);
    const modal = modalFactory($, _, document, window, KEYS);
    const instance = modal(
        openTriggers,
        closeTriggers,
        target.current,
        _.merge(transitionZero(), {}),
    );

    const open = () => {
      setTimeout(instance.open, 0);
    };

    on(EVENTS.CLICK_ON_JOIN, open);
    on(EVENTS.REGISTRATION_SUCCESSFUL, instance.close);

    return () => {
      off(EVENTS.CLICK_ON_JOIN, open);
      off(EVENTS.REGISTRATION_SUCCESSFUL, instance.close);
      instance.destroy();
    };
  }, [target]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    auth
        .register({email: inputEmail})
        .then(() => {
          setInputEmail('');
        })
        .catch(_.pipe(_.prop('errors'), setError))
        .finally(() => {
          setLoading(false);
        });
  };

  return (
    <div
      ref={target}
      className="modal modal-join"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {loading && <Loading />}
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <H5 style="hero">Hello art lovers</H5>
            <P style="p2">Art for everyone, direct from artists.</P>
            {error && (
              <div className="alert alert-danger" role="alert">
                {error.map((x, i) => (
                  <div key={i}>{x.detail}</div>
                ))}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="modal-join-email">Email address</label>
                <input
                  type="email"
                  name="email"
                  value={inputEmail}
                  className={'form-control' + (false ? ' is-invalid' : '')}
                  id="modal-join-email"
                  placeholder="Enter email"
                  onChange={(e) => setInputEmail(e.target.value)}
                />
                {false && (
                  <div className="invalid-feedback">
                    Please enter a valid email address.
                  </div>
                )}
              </div>

              <Button submit>Join</Button>
            </form>

            <div className="modal-login-link">
              <a
                href="#"
                data-dismiss="modal"
                onClick={emitFactory('CLICK_ON_LOGIN')}
              >
                <b>Already have account? Sign in</b>
              </a>
            </div>
            <div className="modal-login-link">
              <Logo title="Artfinder" width="128px" height="26px" />
            </div>
            <div className="modal-login-link modal-join-terms">
              By clicking ‘Join’ you confirm that you have read and accept our{' '}
              <a href="http://help.artfinder.com/article/168-terms-for-users">
                T&amp;Cs
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalJoin;
