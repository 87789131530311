import React, {useEffect, useRef, useState} from 'react';

import _ from '../../utils/_';
import {on, off} from '../../utils/events';
import constantsFactory from '../../utils/constants';
import Button from '../button/button';
import {P} from '../typography/typography';
import useTranslation from '../../hooks/use-translation/use-translation';
import useModal from '../../hooks/use-modal/use-modal';

const {EVENTS} = constantsFactory();

const ModalConfirmation = () => {
  const target = useRef(null);
  const modal = useModal(target);
  const {t} = useTranslation();
  const [msg, setMsg] = useState('');

  useEffect(() => {
    on(EVENTS.OPEN_CONFIRMATION_MSG, setMsg);
    return () => {
      off(EVENTS.OPEN_CONFIRMATION_MSG, setMsg);
    };
  }, []);

  useEffect(() => {
    on(EVENTS.OPEN_CONFIRMATION_MSG, modal.open);

    return () => {
      off(EVENTS.OPEN_CONFIRMATION_MSG, modal.open);
      modal.destroy();
    };
  }, [modal]);

  return (
    <div
      ref={target}
      className="modal modal-confirmation"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <P style="p1" align="center">
              {t(msg)}
            </P>

            <Button dataDismiss="modal">OK</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmation;
