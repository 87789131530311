import React, {useEffect, useRef} from 'react';

import _ from '../../utils/_';
import {on, off, emitFactory} from '../../utils/events';
import elementFactory from '../../utils/element';
import constantsFactory from '../../utils/constants';
import modalFactory from '../../../vendors/bootstrap/components/modal';
import {transitionZero} from '../../../vendors/bootstrap/components/modal';
import Button from '../button/button';
import {H5, P} from '../typography/typography';

const ModalWelcome = () => {
  const target = useRef(null);

  useEffect(() => {
    const $ = elementFactory(document, window);
    const {EVENTS, KEYS} = constantsFactory();
    const openTriggers = [];
    const closeTriggers = $.findAll('[data-dismiss="modal"]', target.current);
    const modal = modalFactory($, _, document, window, KEYS);
    const instance = modal(
        openTriggers,
        closeTriggers,
        target.current,
        _.merge(transitionZero(), {}),
    );

    const open = () => {
      setTimeout(instance.open, 0);
    };

    on(EVENTS.NEWSLETTER_DISMISS, open);

    return () => {
      off(EVENTS.NEWSLETTER_DISMISS, open);
      instance.destroy();
    };
  }, [target]);

  return (
    <div
      ref={target}
      className="modal modal-welcome"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="modal-welcome-half">
              <img
                loading="lazy"
                src="https://d2m7ibezl7l5lt.cloudfront.net/img/onboarding/welcome.427802c3b21b.jpg"
                alt="Painting workbench"
                width="auto"
                height="570px"
              />
            </div>
            <div className="modal-welcome-half">
              <H5 style="title" align="center">
                Thanks for joining!
              </H5>
              <P style="p1" align="center">
                Welcome, you’re our newest Artfinder. Take a few seconds to tell
                us about yourself and learn about our marketplace.
              </P>
              <div className="modal-welcome-link">
                <Button
                  submit
                  dataDismiss="modal"
                  onClick={emitFactory('CLICK_TELL_ABOUT_YOURSELF')}
                  wide
                >
                  Get Started
                </Button>
              </div>
              <div className="modal-welcome-link">
                <a href="#" data-dismiss="modal">
                  No thanks, take me to the art
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalWelcome;
