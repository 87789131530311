import React, {useRef, useEffect, useState} from 'react';

import _ from '../../utils/_';
import {on, off} from '../../utils/events';

import useModal from '../../hooks/use-modal/use-modal';
import constantsFactory from '../../utils/constants';

const {EVENTS} = constantsFactory();

import DropdownSelect from '../dropdown-select/dropdown-select';
import Loading from '../loading/loading';
import Button from '../button/button';
import {H5} from '../typography/typography';
import {useDispatch, useSelector} from 'react-redux';
import {saveUserSettings} from '../../../redux/actions/settingsActions';
import {selectUserSettingsData} from '../../../redux/reducers/userSettingsReducer';
import {selectSettingsOptionsData} from '../../../redux/reducers/settingsOptionsReducer';
import {useTranslation} from 'react-i18next';
import useMounted from '../../hooks/use-mounted/use-mounted';

const label = (x) => `${x.label}${x.required ? ' *' : ''}`;

const ModalRegionalSettings = () => {
  const dispatch = useDispatch();
  const target = useRef(null);
  const modal = useModal(target);
  const {i18n} = useTranslation();
  const hasMounted = useMounted();

  const mainSettings = useSelector(selectUserSettingsData);
  const options = useSelector(selectSettingsOptionsData);

  const initialFormData = {
    currency: mainSettings.currency,
    country: mainSettings.country,
    display_units: mainSettings.display_units,
    language: mainSettings.language,
  };

  const [form, setForm] = useState(initialFormData);

  useEffect(() => {
    on(EVENTS.CLICK_CHANGE_SETTINGS, modal.open);
    return () => {
      off(EVENTS.CLICK_CHANGE_SETTINGS, modal.open);
      modal.destroy();
    };
  }, [modal]);

  useEffect(() => {
    if (!hasMounted) return;
    const newFormData = {
      currency: mainSettings.currency,
      country: mainSettings.country,
      display_units: mainSettings.display_units,
      language: mainSettings.language,
    };
    setForm(newFormData);
  }, [
    mainSettings.currency,
    mainSettings.country,
    mainSettings.display_units,
    mainSettings.language,
  ]);

  const handleChange = (prop) => (value) => {
    setForm(_.assoc(prop, value, form));
  };

  const changeLng = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(saveUserSettings(form));
    changeLng(form.language);
    modal.close();
  };

  return (
    <div ref={target} className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <form onSubmit={handleSubmit}>
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <H5 style="art">Your Regional Settings</H5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {!options && (
                <div className="modal-body">
                  <Loading />
                </div>
              )}
              {options && (
                <div className="modal-body">
                  <div className="form-group">
                    {options?.currency && (
                      <DropdownSelect
                        id="rs-currency"
                        label={label(options.currency)}
                        value={form.currency}
                        items={options.currency.values}
                        getValue={_.prop('value')}
                        onChange={handleChange('currency')}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    {options?.country && (
                      <DropdownSelect
                        id="rs-country"
                        label={label(options.country)}
                        value={form.country}
                        items={options.country.values}
                        getValue={_.prop('value')}
                        onChange={handleChange('country')}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    {options?.display_units && (
                      <DropdownSelect
                        id="rs-display_units"
                        label={label(options.display_units)}
                        value={form.display_units}
                        items={options.display_units.values}
                        getValue={_.prop('value')}
                        onChange={handleChange('display_units')}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    {options?.language && (
                      <DropdownSelect
                        id="rs-language"
                        label={label(options.language)}
                        value={form.language}
                        items={options.language.values}
                        getValue={_.prop('value')}
                        onChange={handleChange('language')}
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="modal-footer">
                <Button secondary dataDismiss="modal">
                  Cancel
                </Button>

                <Button submit>Save changes</Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalRegionalSettings;
