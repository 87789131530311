import React from 'react';

const Logo = ({width = '300px', height = '53px', title}) => (
  <svg
    width={width}
    height={height}
    className="bi bi-logo"
    viewBox="0 6 470 83"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <g fillRule="evenodd">
      <path d="m31.23 47.54h15.87l-7.93-27.34zm21.45-42.54a3.47 3.47 0 0 1 3.32 2.65l22 68a9.46 9.46 0 0 1 .32 2.15 2.26 2.26 0 0 1 -2.48 2.2h-17.37a3.07 3.07 0 0 1 -2.68-2.57l-3.43-11.9h-26.36l-3.43 11.9a3.07 3.07 0 0 1 -2.7 2.57h-16.4a2.26 2.26 0 0 1 -2.47-2.23 9.4 9.4 0 0 1 .32-2.15l22-68a3.47 3.47 0 0 1 3.3-2.62z" />
      <path d="m103.28 77.77a2.49 2.49 0 0 1 -2.57 2.23h-13.51a2.43 2.43 0 0 1 -2.46-2.47v-48.11a2.43 2.43 0 0 1 2.46-2.42h12.8a2.49 2.49 0 0 1 2.57 2.25v3c3.22-4.5 9-6.22 13.29-6.22h3c1.18 0 2.9.43 2.9 3.22v13.14a3.08 3.08 0 0 1 -3.32 3.32h-5.25c-7.08 0-9.86 1.82-9.86 7.94z" />
      <path d="m136 42.82h-6.54a2.52 2.52 0 0 1 -2.47-2.57v-10.83a2.43 2.43 0 0 1 2.42-2.42h6.59v-13.13a2.52 2.52 0 0 1 2.57-2.46h13.43a2.43 2.43 0 0 1 2.47 2.46v13.13h13.23a2.52 2.52 0 0 1 2.57 2.47v10.78a2.61 2.61 0 0 1 -2.57 2.57h-13.2v15.87c0 4.82 3.22 4.93 4.18 4.93a6.46 6.46 0 0 0 4-1.29 4.29 4.29 0 0 1 2.57-1.07 2.77 2.77 0 0 1 2.36 1.18l5.9 8.25a4.24 4.24 0 0 1 .86 2.25 3.41 3.41 0 0 1 -1.07 2.25c-3.86 4.29-11.58 5.9-17.26 5.9-9.76 0-20-4.83-20-20.48z" />
      <path d="m235.4 80.1h-13.51a2.61 2.61 0 0 1 -2.57-2.57v-48a2.52 2.52 0 0 1 2.57-2.53h13.51a2.43 2.43 0 0 1 2.47 2.47v48a2.52 2.52 0 0 1 -2.47 2.57" />
      <path d="m283.74 53.43c0-6.54-4-10.93-10.19-10.93s-10.4 4.4-10.4 10.93v24.34a2.49 2.49 0 0 1 -2.57 2.23h-13.51a2.43 2.43 0 0 1 -2.47-2.47v-48.11a2.43 2.43 0 0 1 2.47-2.42h13.51a2.49 2.49 0 0 1 2.57 2.25v1.93c3.22-3.11 8.15-5.79 16-5.79 16.73 0 23.16 12.22 23.16 26.59v25.79a2.49 2.49 0 0 1 -2.59 2.23h-13.4a2.49 2.49 0 0 1 -2.57-2.25z" />
      <path d="m336.81 42.61a10.94 10.94 0 1 0 10.72 10.93 10.8 10.8 0 0 0 -10.72-10.93m13.29-37.61h13.4a2.43 2.43 0 0 1 2.5 2.44v70.12a2.43 2.43 0 0 1 -2.49 2.44h-13.41a2.43 2.43 0 0 1 -2.47-2.47v-1.53c-2.68 3.32-7.4 5.68-13.72 5.68-13.51 0-26.48-10.83-26.48-28.3s13.08-28 26.38-28c6.54 0 11.58 2.47 13.83 5.36v-23.3a2.49 2.49 0 0 1 2.46-2.44" />
      <path d="m450.56 77.77a2.49 2.49 0 0 1 -2.56 2.23h-13.52a2.43 2.43 0 0 1 -2.48-2.44v-48.14a2.43 2.43 0 0 1 2.48-2.42h12.76a2.49 2.49 0 0 1 2.57 2.25v3c3.22-4.5 9-6.22 13.29-6.22h3c1.18 0 2.9.43 2.9 3.22v13.14a3.08 3.08 0 0 1 -3.32 3.32h-5.25c-7.08 0-9.86 1.82-9.86 7.94z" />
      <path d="m390.48 47.27s.81-7.83 9.49-7.83 9.43 7.78 9.43 7.78zm9.37-21.92c-16.4 0-28.14 12.65-28.14 28.52 0 15.44 10.91 27.77 28.39 27.77 10.2 0 20-4.13 24.14-11.59a1.88 1.88 0 0 0 -.62-2.64s-9.83-6.18-11.2-7a2 2 0 0 0 -2.88.62 11.43 11.43 0 0 1 -9.36 4.76c-5.79 0-9.54-3.68-9.86-8.8h33.13c2.36 0 3.1-1.38 3.1-2.88v-2.28c0-13-9.76-26.48-26.69-26.48" />
      <path d="m186 80a2.5 2.5 0 0 1 -2.51-2.43v-35h-6.4a2.1 2.1 0 0 1 -2.31-2.21v-11.42a2.3 2.3 0 0 1 2.31-2.23h6.4v-3.79c0-7.24 2.19-12.84 6.43-16.65a20.88 20.88 0 0 1 14-4.91c5.61 0 13.32 1.85 17 5.9a2.25 2.25 0 0 1 0 3.11l-7.72 8.43a2.5 2.5 0 0 1 -3.39.27 4.72 4.72 0 0 0 -3-1h-.12a4.93 4.93 0 0 0 -4.69 4.93v3.7h8.7a2.89 2.89 0 0 1 2.58 2.86v10.38a2.6 2.6 0 0 1 -2.58 2.66h-8.7v35a2.21 2.21 0 0 1 -2.24 2.4z" />
    </g>
  </svg>
);

export default Logo;
