import _ from '../utils/_';
import {emit} from '../utils/events';
import {post} from './artfinder-service';
import {loginEvent, registrationEvent} from '../utils/gtm';

const getPayload = (token, window) => window.JSON.parse(window.atob(token.split('.')[1]));

const headers = () => ({
  'X-API-KEY': process.env.API_AUTH_X_API_KEY,
});

const authFactory = (document, window) => {
  const token = _.getCookie('sessionid', document);
  const isTokenValid = !!token;
  const user = isTokenValid ? getPayload(token, window) || {} : {};

  const setData = (data) => {
    document.cookie =
      'sessionid=' +
      data.access_token +
      '; path=/; max-age=' +
      60 * 60 * 24 * 30 +
      '; samesite=lax; secure';
  };

  const clearData = () => {
    document.cookie = 'sessionid=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure';
  };

  const isAuthenticated = () => isTokenValid && _.def(user.sub);

  const getScopes = () => user.scopes || [];

  const getUserType = () => {
    if (user.scopes) {
      if (user.scopes.includes('staff')) {
        return 'staff';
      } else if (user.scopes.includes('provider')) {
        return 'provider';
      } else if (user.scopes.includes('user')) {
        return 'registered';
      }
    }

    return 'anonymous';
  };

  const getUserId = () => user.sub || false;

  const getUserEmail = () => user.email || false;

  const getUserFirstName = () => user.first_name || false;

  const getUserLastName = () => user.last_name || false;

  const isAuthorised = (roles) =>
    isAuthenticated() && getScopes().some((x) => roles.indexOf(x) !== -1);

  const register = (form) =>
    post('/api/u/register/', form, {headers: headers()})
      .then((res) => {
        setData(res);
        setTimeout(() => {
          emit('REGISTRATION_SUCCESSFUL', authFactory(document, window));
          registrationEvent();
        }, 0);
      })
      .catch((err) => {
        console.log('register error', err);
      });

  const login = (form) =>
    post('/api/u/login/', form, {headers: headers()})
      .then((res) => {
        setData(res);
        setTimeout(() => {
          emit('LOGIN_SUCCESS', authFactory(document, window));
        }, 0);
      })
      .catch((err) => {
        console.log('login error', err);
      });

  const logout = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        clearData();
        setTimeout(() => {
          resolve('LOGOUT_SUCCESS');
          emit('LOGOUT_SUCCESS', authFactory(document, window));
        }, 0);
      }, 100);
    });
  };

  if (!isTokenValid && process.env.API_AUTH_X_API_KEY) {
    post('/api/u/session/', null, {headers: headers()})
      .then((res) => {
        setData(res);
        setTimeout(() => {
          emit('GUEST_SESSION_CREATED', authFactory(document, window));
        }, 0);
      })
      .catch((err) => {
        console.log('session creation error', err);
      });
  }

  return {
    register,
    login,
    logout,
    isAuthenticated,
    isAuthorised,
    getUserId,
    getScopes,
    getUserType,
    getUserEmail,
    getUserFirstName,
    getUserLastName,
  };
};

const authFactoryThrottled = _.throttle({}, 5000, authFactory);

const noopPromise = () => ({
  then: (_) => noopPromise(),
  catch: (_) => noopPromise(),
  finally: (_) => noopPromise(),
});

const authMock = () => ({
  isAuthenticated: () => false,
  isAuthorised: (_) => false,
  getUserId: (_) => false,
  getScopes: (_) => false,
  getUserType: (_) => false,
  getUserEmail: (_) => false,
  register: (_) => noopPromise(),
  login: (_) => noopPromise(),
  logout: (_) => noopPromise(),
});

export default authFactoryThrottled;

export {authMock};
