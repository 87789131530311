/* eslint-disable camelcase */
import {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
  STATUS_FAILED,
  LOAD_USER_SETTINGS_REQUEST,
  LOAD_USER_SETTINGS_SUCCESS,
  LOAD_USER_SETTINGS_FAILURE,
  SAVE_USER_SETTINGS_REQUEST,
  SAVE_USER_SETTINGS_SUCCESS,
  SAVE_USER_SETTINGS_FAILURE,
  STATUS_UPDATING,
  INCREASE_BASKET_COUNT,
} from '../actionTypes';

const userSettingsInitialState: UserSettingsInitialState = {
  status: STATUS_IDLE,
  data: {
    basket_count: 0,
    unread_count: 0,
    review_count: 0,
    currency: 'GBP',
    currency_symbol: '£',
    collections_enabled: false,
    country: 'GB',
    country_display: 'United Kingdom',
    display_units: 'cm',
    language: 'en-gb',
    language_display: 'British English',
    psychobar: null,
    credit_data: {
      display_text: '',
      url: '',
    },
    dislike_collective: null,
  },
  error: null,
};

const userSettingsReducer = (state = userSettingsInitialState, action) => {
  switch (action.type) {
    case INCREASE_BASKET_COUNT:
      return {
        ...state,
        data: {...state.data, basket_count: state.data.basket_count + action.payload},
      };
    case LOAD_USER_SETTINGS_REQUEST:
      return {...state, status: STATUS_LOADING};
    case LOAD_USER_SETTINGS_SUCCESS:
      return {...state, status: STATUS_SUCCEEDED, data: {...state.data, ...action.response.data}};
    case LOAD_USER_SETTINGS_FAILURE:
      return {...state, status: STATUS_FAILED, error: action.error};
    case SAVE_USER_SETTINGS_REQUEST:
      return {...state, status: STATUS_UPDATING};
    case SAVE_USER_SETTINGS_SUCCESS:
      return {...state, status: STATUS_SUCCEEDED, data: {...state.data, ...action.response.data}};
    case SAVE_USER_SETTINGS_FAILURE:
      return {...state, status: STATUS_FAILED, error: action.error};
    default:
      return {...state};
  }
};

export default userSettingsReducer;

// Export a reusable selector so state structure is abstracted for components
export const selectUserSettingsData = (state): UserSettingsData => state.userSettings.data;

type CurrencyType = 'GBP' | 'USD' | 'EUR' | 'CAD' | 'AUD';
type CurrencySymbolType = '£' | '$' | '€' | 'C$' | 'A$';
type UnitType = 'cm' | 'in';

interface UserSettingsData {
  basket_count: number;
  unread_count: number;
  review_count: number;
  currency: CurrencyType;
  currency_symbol: CurrencySymbolType;
  collections_enabled: boolean;
  country: string;
  country_display: string;
  display_units: UnitType;
  language: string;
  language_display: string;
  psychobar: null;
  credit_data: {
    display_text: string;
    url: string;
  };
  dislike_collective: null;
}

type StatusType =
  | typeof STATUS_IDLE
  | typeof STATUS_LOADING
  | typeof STATUS_SUCCEEDED
  | typeof STATUS_FAILED;

interface UserSettingsInitialState {
  status: StatusType;
  data: UserSettingsData;
  error: string | null;
}
