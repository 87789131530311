import React, {useState, useEffect, useRef} from 'react';

import _ from '../../utils/_';
import {newsletterSubscriptionEvent} from '../../utils/gtm';
import {on, off, emitFactory, emit} from '../../utils/events';
import elementFactory from '../../utils/element';
import constantsFactory from '../../utils/constants';
import modalFactory from '../../../vendors/bootstrap/components/modal';
import {transitionZero} from '../../../vendors/bootstrap/components/modal';
import {put} from '../../services/artfinder-service';

import Loading from '../loading/loading';
import Check from '../icons-af/check';
import Button from '../button/button';
import {H5, P} from '../typography/typography';

const ModalNewsletter = () => {
  const target = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError]: any = useState(null);

  useEffect(() => {
    const $ = elementFactory(document, window);
    const {EVENTS, KEYS} = constantsFactory();
    const openTriggers = [];
    const closeTriggers = $.findAll('[data-dismiss="modal"]', target.current);
    const modal = modalFactory($, _, document, window, KEYS);
    const instance = modal(
        openTriggers,
        closeTriggers,
        target.current,
        _.merge(transitionZero(), {}),
    );

    const open = () => {
      setTimeout(instance.open, 0);
    };

    on(EVENTS.REGISTRATION_SUCCESSFUL, open);
    on(EVENTS.NEWSLETTER_DISMISS, instance.close);

    return () => {
      off(EVENTS.REGISTRATION_SUCCESSFUL, open);
      off(EVENTS.NEWSLETTER_DISMISS, instance.close);
      instance.destroy();
    };
  }, [target]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    put('/api/u/subscriptions/', {
      marketing_email: true,
      activity_digest_email: true,
      promotions_email: true,
      consent_version: 3,
      source: 'onboarding',
    })
        .then(() => {
          newsletterSubscriptionEvent();
          emit('NEWSLETTER_DISMISS');
        })
        .catch(setError)
        .finally(() => {
          setLoading(false);
        });
  };

  return (
    <div
      ref={target}
      className="modal modal-newsletter"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          {loading && <Loading />}
          <div className="modal-body">
            {error && (
              <div className="alert alert-danger" role="alert">
                {Object.keys(error).map((x) => (
                  <div key={x}>
                    {x} : {error[x]}
                  </div>
                ))}
              </div>
            )}
            <H5 style="title" align="center">
              Would you like to get inspirational emails?
            </H5>
            <ul className="modal-newsletter-ticklist list-unstyled">
              <li>
                <Check /> Receive 10% off your first purchase
              </li>
              <li>
                <Check /> Receive beautiful inspirational newsletters crafted by
                our editors
              </li>
              <li>
                <Check /> Be the first to know about your favourite artist's new
                artworks or offers
              </li>
              <li>
                <Check /> Get recommendations and special offers tailored to you
              </li>
            </ul>
            <P style="p1" color="secondary" align="center">
              No risk trial. Change your email preferences or unsubscribe at
              anytime!
            </P>
            <div className="modal-login-link">
              <Button submit onClick={handleSubmit}>
                Yes please, sign me up!
              </Button>
            </div>
            <div className="modal-login-link">
              <a href="#" onClick={emitFactory('NEWSLETTER_DISMISS')}>
                <b>No thanks</b>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalNewsletter;
