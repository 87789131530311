import React, {useEffect, useRef, useState} from 'react';

import _ from '../../utils/_';
import {on, off} from '../../utils/events';
import elementFactory from '../../utils/element';
import constantsFactory from '../../utils/constants';
import modalFactory from '../../../vendors/bootstrap/components/modal';
import {transitionZero} from '../../../vendors/bootstrap/components/modal';
import Button from '../button/button';
import {H5, P} from '../typography/typography';

const ModalWelcome = () => {
  const target = useRef(null);
  const [user, setUser]: any = useState({});

  useEffect(() => {
    const $ = elementFactory(document, window);
    const {EVENTS, KEYS} = constantsFactory();
    const openTriggers = [];
    const closeTriggers = $.findAll('[data-dismiss="modal"]', target.current);
    const modal = modalFactory($, _, document, window, KEYS);
    const instance = modal(
        openTriggers,
        closeTriggers,
        target.current,
        _.merge(transitionZero(), {}),
    );

    const open = (user) => {
      setUser(user);
      setTimeout(instance.open, 0);
    };

    on(EVENTS.USER_UPDATED, open);

    return () => {
      off(EVENTS.USER_UPDATED, open);
      instance.destroy();
    };
  }, [target]);

  return (
    <div
      ref={target}
      className="modal modal-welcome"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div
              className="modal-welcome-half"
              // style={{
              //   backgroundImage:
              //     'url(https://d2m7ibezl7l5lt.cloudfront.net/img/onboarding/thanks.d6f96d709b80.jpg)',
              // }}
            ></div>
            <div className="modal-welcome-half">
              <H5 style="title" align="center">
                Thanks {user.first_name}!
              </H5>
              <P style="p1" align="center">
                You’re all set. You can now browse thousands of original
                artworks from artists around the world. What will you discover?
              </P>
              <div className="modal-welcome-link">
                <Button submit dataDismiss="modal" wide>
                  Start Discovering
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalWelcome;
