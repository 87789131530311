import axios from 'axios';
import constantsFactory from '../../src/utils/constants';

// Action Types
import {
  STATUS_SUCCEEDED,
  STATUS_LOADING,
  STATUS_UPDATING,
  LOAD_USER_SETTINGS_REQUEST,
  LOAD_USER_SETTINGS_SUCCESS,
  LOAD_USER_SETTINGS_FAILURE,
  LOAD_SETTINGS_OPTIONS_REQUEST,
  LOAD_SETTINGS_OPTIONS_SUCCESS,
  LOAD_SETTINGS_OPTIONS_FAILURE,
  SAVE_USER_SETTINGS_REQUEST,
  SAVE_USER_SETTINGS_SUCCESS,
  SAVE_USER_SETTINGS_FAILURE,
  INCREASE_BASKET_COUNT,
} from '../actionTypes';

// private actions
const {API} = constantsFactory();
const URL = `${process.env.HOST}${API.SETTINGS_API}`;

/**
 * Async action creator - User Settings   (depends on callAPIMiddleware)
 */
export function loadUserSettings() {
  return {
    // Types of actions to emit before and after
    types: [LOAD_USER_SETTINGS_REQUEST, LOAD_USER_SETTINGS_SUCCESS, LOAD_USER_SETTINGS_FAILURE],
    // Check if we already have a cached result (optional):
    shouldCallAPI: (state) =>
      state.userSettings.status !== STATUS_LOADING &&
      state.userSettings.status !== STATUS_SUCCEEDED,
    // Perform the fetching:
    callAPI: () => axios.get(URL, {headers: {Accept: 'application/json'}}),
    // Arguments to inject in begin/end actions
    payload: {},
  };
}

/**
 * Async action creator - User Settings   (depends on callAPIMiddleware)
 */
export function saveUserSettings(
  data,
  onSuccessCallbacks = [],
  onErrorCallbacks = [],
  cleanupCallbacks = [],
) {
  return {
    // Types of actions to emit before and after
    types: [SAVE_USER_SETTINGS_REQUEST, SAVE_USER_SETTINGS_SUCCESS, SAVE_USER_SETTINGS_FAILURE],
    // Check if we already have a cached result (optional):
    shouldCallAPI: (state) => state.userSettings.status !== STATUS_UPDATING,
    // Perform the fetching:
    callAPI: () => axios.put(URL, data, {headers: {Accept: 'application/json'}}),
    // Arguments to inject in begin/end actions
    payload: {data: data},
    // Functions to run on success
    onSuccess: onSuccessCallbacks,
    // Functions to run to on cleanup
    onCleanup: cleanupCallbacks,
    // Functions to run on Error
    onError: onErrorCallbacks,
  };
}

/**
 * Async action creator - Settings Options   (depends on callAPIMiddleware)
 */
export function loadSettingsOptions() {
  return {
    // Types of actions to emit before and after
    types: [
      LOAD_SETTINGS_OPTIONS_REQUEST,
      LOAD_SETTINGS_OPTIONS_SUCCESS,
      LOAD_SETTINGS_OPTIONS_FAILURE,
    ],
    // Check if we already have a cached result (optional):
    shouldCallAPI: (state) =>
      state.settingsOptions.status !== STATUS_LOADING &&
      state.settingsOptions.status !== STATUS_SUCCEEDED,
    // Perform the fetching:
    callAPI: () => axios.options(URL, null, {headers: {Accept: 'application/json'}}),
    // Arguments to inject in begin/end actions
    payload: {},
  };
}

export const increaseBasketCount = (amount) => {
  return {
    type: INCREASE_BASKET_COUNT,
    payload: amount,
  };
};
